<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M10.5 21C4.701 21 0 16.299 0 10.5S4.701 0 10.5 0 21 4.701 21 10.5 16.299 21 10.5 21zm0-1a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19z M14.2 8.1a.5.5 0 0 1 .6.8l-5.333 4a.5.5 0 0 1-.654-.046l-2.667-2.667a.5.5 0 1 1 .708-.707l2.36 2.36L14.2 8.1z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
